<script setup lang="ts">
import { Head, Link, useForm } from '@inertiajs/vue3'
import AuthenticationCard from '@/components/AuthenticationCard.vue'
import AuthenticationCardFooter from '@/components/AuthenticationCardFooter.vue'
import AuthenticationCardLogo from '@/components/AuthenticationCardLogo.vue'
import Checkbox from '@/components/Checkbox.vue'
import InputError from '@/components/InputError.vue'
import InputLabel from '@/components/InputLabel.vue'
import PrimaryButton from '@/components/PrimaryButton.vue'
import TextInput from '@/components/TextInput.vue'
import route from 'ziggy-js'

interface Props {
  canResetPassword: boolean
  status?: string
}

defineProps<Props>()

const form = useForm({
  email: '',
  password: '',
  remember: false,
})

const submit = () => {
  form
    .transform((data) => ({
      ...data,
      remember: form.remember ? 'on' : '',
    }))
    .post(route('login'), {
      onFinish: () => form.reset('password'),
    })
}
</script>

<template>
  <Head title="Log in" />

  <AuthenticationCard>
    <template #logo>
      <AuthenticationCardLogo />
    </template>

    <div
      v-if="status"
      class="mb-4 font-medium text-sm text-green-600 dark:text-green-400">
      {{ status }}
    </div>

    <form @submit.prevent="submit">
      <div>
        <InputLabel
          for="email"
          value="Email" />
        <TextInput
          id="email"
          v-model="form.email"
          type="email"
          class="mt-1 block w-full"
          required
          autofocus
          autocomplete="username" />
        <InputError
          class="mt-2"
          :message="form.errors.email" />
      </div>

      <div class="mt-4">
        <InputLabel
          for="password"
          value="Password" />
        <TextInput
          id="password"
          v-model="form.password"
          type="password"
          class="mt-1 block w-full"
          required
          autocomplete="current-password" />
        <InputError
          class="mt-2"
          :message="form.errors.password" />
      </div>

      <div class="block mt-4">
        <label class="flex items-center">
          <Checkbox
            v-model:checked="form.remember"
            name="remember" />
          <span class="ms-2 text-sm text-gray-600 dark:text-gray-400">Remember me</span>
        </label>
      </div>

      <div class="flex items-center justify-end mt-4">
        <Link
          v-if="canResetPassword"
          :href="route('password.request')"
          class="underline text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800">
          Forgot your password?
        </Link>

        <PrimaryButton
          class="ms-4"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing">
          Log in
        </PrimaryButton>
      </div>
    </form>

    <template #footer>
      <AuthenticationCardFooter />
    </template>
  </AuthenticationCard>
</template>
